import { createSlice } from '@reduxjs/toolkit';

const myOwnDomain = 'makhnev.site';

const initialState = [
  {
    IsBest: false,
    Name: 'Luftbase',
    Type: 'html',
    Img: 'html/luftbase',
    Link: `https://demo.${myOwnDomain}/luftbase/`,
  },
  {
    IsBest: false,
    Name: 'GetPort',
    Type: 'html',
    Img: 'html/getport',
    Link: `https://demo.${myOwnDomain}/get-port/`,
  },
  {
    IsBest: false,
    Name: 'Yeezypay - finance',
    Type: 'html',
    Img: 'html/yeezypay-finance',
    Link: `http://demo.${myOwnDomain}/yeezypay_finance/`,
  },
  {
    IsBest: false,
    Name: 'Pixture',
    Type: 'html',
    Img: 'html/pixture',
    Link: `https://demo.${myOwnDomain}/pixture/`,
  },
  {
    IsBest: false,
    Name: 'Will Towers',
    Type: 'html',
    Img: 'html/willtowers',
    Link: 'https://willtowers.ru/',
  },
  {
    IsBest: false,
    Name: 'SmartPay',
    Type: 'html',
    Img: 'html/smartpay',
    Link: `https://demo.${myOwnDomain}/smartpay/`,
  },
  {
    IsBest: false,
    Name: 'LCS',
    Type: 'html',
    Img: 'html/lcs',
    Link: `https://demo.${myOwnDomain}/lcs/`,
  },
  {
    IsBest: false,
    Name: 'NFTop',
    Type: 'html',
    Img: 'html/nftop',
    Link: `https://demo.${myOwnDomain}/NFTop/`,
  },
  {
    IsBest: false,
    Name: 'Weather Check',
    Type: 'vue',
    Img: 'vue/weathercheck',
    Link: `https://demo.${myOwnDomain}/weather-vue/`,
  },
  {
    IsBest: false,
    Name: 'Rebank',
    Type: 'html',
    Img: 'html/rebank',
    Link: `https://demo.${myOwnDomain}/rebank/`,
  },
  {
    IsBest: false,
    Name: 'Yeezypay',
    Type: 'html',
    Img: 'html/yeezypay',
    Link: `https://demo.${myOwnDomain}/yeezypay/sitemap.html`,
  },
  {
    IsBest: false,
    Name: 'Card To Card',
    Type: 'html',
    Img: 'html/card-to-card',
    Link: `https://demo.${myOwnDomain}/card-to-card/`,
  },
  {
    IsBest: false,
    Name: 'Agora',
    Type: 'html',
    Img: 'html/agora',
    Link: `https://demo.${myOwnDomain}/agora/`,
  },
  {
    IsBest: false,
    Name: 'TaxiPay',
    Type: 'html',
    Img: 'html/taxipay',
    Link: `https://demo.${myOwnDomain}/taxipay/`,
  },
  {
    IsBest: false,
    Name: 'NFT Banker',
    Type: 'html',
    Img: 'html/nftbanker',
    Link: `https://demo.${myOwnDomain}/nftbanker/`,
  },
  {
    IsBest: false,
    Name: 'Fredemo',
    Type: 'html',
    Img: 'html/fredemo',
    Link: `https://demo.${myOwnDomain}/fredemo/`,
  },
  {
    IsBest: false,
    Name: 'Yeezypay - conf',
    Type: 'html',
    Img: 'html/yeezypay-conf',
    Link: `http://demo.${myOwnDomain}/yeezypay_conf/`,
  },
  {
    IsBest: false,
    Name: 'Advertex',
    Type: 'html',
    Img: 'html/advertex',
    Link: `https://demo.${myOwnDomain}/advertex/`,
  },
  {
    IsBest: false,
    Name: 'Sports Bros',
    Type: 'react',
    Img: 'react/sports-bros',
    Link: `https://sportsbros.${myOwnDomain}/`,
  },
  {
    IsBest: false,
    Name: 'Atlant',
    Type: 'html',
    Img: 'html/atlant',
    Link: `https://demo.${myOwnDomain}/inproc/sitemap.html`,
  },
  {
    IsBest: true,
    Name: 'BotSwap',
    Type: 'react',
    Img: 'react/botswap',
    Link: `https://botswap.${myOwnDomain}/`,
  },
  {
    IsBest: false,
    Name: 'Zelenec',
    Type: 'html',
    Img: 'html/zelenec',
    Link: `https://demo.${myOwnDomain}/zelenec/sitemap.html`,
  },
  {
    IsBest: true,
    Name: 'Code Metal',
    Type: 'html',
    Img: 'html/metal',
    Link: `http://demo.${myOwnDomain}/metal/sitemap.html`,
  },
  {
    IsBest: false,
    Name: 'Freedemo app',
    Type: 'react',
    Img: 'react/freedemo_app',
    Link: `https://freedemo.${myOwnDomain}/`,
  },
  {
    IsBest: false,
    Name: 'Yeezypay - collab',
    Type: 'react',
    Img: 'react/yeezypay-collab',
    Link: `https://yeezypaycollab.${myOwnDomain}/`,
  },
  {
    IsBest: false,
    Name: 'Specokraska',
    Type: 'html',
    Img: 'html/specokraska',
    Link: `https://demo.${myOwnDomain}/specokraska/index.html`,
  },
  {
    IsBest: false,
    Name: 'Authors',
    Type: 'html',
    Img: 'html/authors',
    Link: `https://demo.${myOwnDomain}/authors/sitemap.html`,
  },
  {
    IsBest: false,
    Name: 'Paradox - landing',
    Type: 'react',
    Img: 'react/paradox-index',
    Link: `https://paradox.${myOwnDomain}/`,
  },
  {
    IsBest: false,
    Name: 'Paradox - dashboard',
    Type: 'react',
    Img: 'react/paradox-dashboard',
    Link: `https://paradox.${myOwnDomain}/dashboard/overview`,
  },
  {
    IsBest: true,
    Name: 'Pikamoon',
    Type: 'react',
    Img: 'react/pikamoon',
    Link: `https://pikamoon.${myOwnDomain}/`,
  },
  {
    IsBest: false,
    Name: 'Pikamoon - dashboard',
    Type: 'react',
    Img: 'react/pikamoon-db',
    Link: `https://pikamoon.${myOwnDomain}/dashboard/`,
  },
  {
    IsBest: false,
    Name: 'noaxarta',
    Type: 'react',
    Img: 'react/noaxarta',
    Link: 'https://dev-game.pro/',
  },
  {
    IsBest: false,
    Name: 'Ballin Venture Capital',
    Type: 'react',
    Img: 'react/ballin',
    Link: `https://ballin.${myOwnDomain}/`,
  },
  {
    IsBest: false,
    Name: 'Authors V2',
    Type: 'html',
    Img: 'html/authors_v2',
    Link: `https://demo.${myOwnDomain}/authors_v2/`,
  },
  {
    IsBest: false,
    Name: 'Boddea',
    Type: 'html',
    Img: 'html/boddea',
    Link: `https://demo.${myOwnDomain}/boddea/`,
  },
  {
    IsBest: false,
    Name: 'Open Travel',
    Type: 'html',
    Img: 'html/open_travel',
    Link: `https://demo.${myOwnDomain}/open_travel/`,
  },
  {
    IsBest: false,
    Name: 'Algoquant',
    Type: 'react',
    Img: 'react/algoquant',
    Link: `https://algoquant.${myOwnDomain}/`,
  },
  {
    IsBest: true,
    Name: 'Rsh | Yandex',
    Type: 'react',
    Img: 'react/rshyandex',
    Link: `https://rshyandex.${myOwnDomain}/`,
  },
  {
    IsBest: true,
    Name: 'Rich Giveaway',
    Type: 'react',
    Img: 'react/richgive',
    Link: `https://richgive.${myOwnDomain}/`,
  },
  {
    IsBest: false,
    Name: 'VMD',
    Type: 'react',
    Img: 'react/vmd',
    Link: `https://vmd.${myOwnDomain}/`,
  },
  {
    IsBest: true,
    Name: 'Zeno',
    Type: 'html',
    Img: 'html/zeno',
    Link: `https://demo.${myOwnDomain}/zeno/`,
  },
  {
    IsBest: true,
    Name: 'Authors Na Bolshoy',
    Type: 'html',
    Img: 'html/authors_nabolshoy',
    Link: `https://demo.${myOwnDomain}/authors-nabolshoy/sitemap.html`,
  },
  {
    IsBest: false,
    Name: 'Algoquant v2',
    Type: 'react',
    Img: 'react/algoquant_v2',
    Link: `https://algoperfomance.${myOwnDomain}/`,
  },
  {
    IsBest: true,
    Name: 'Цифровой Улей',
    Type: 'react',
    Img: 'react/ecpp',
    Link: `http://ecpp.${myOwnDomain}`,
  },
  {
    IsBest: true,
    Name: 'Tainet',
    Type: 'react',
    Img: 'react/tainet',
    Link: `http://tainet.${myOwnDomain}`,
  },
  {
    IsBest: true,
    Name: 'OTSU',
    Type: 'react',
    Img: 'react/otsu',
    Link: `http://otsu.${myOwnDomain}`,
  },
];

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    uploadProjectList: (state, action) => {
      state = action.payload;
    },
  },
});

export const { uploadProjectList } = projectsSlice.actions;

export default projectsSlice.reducer;
